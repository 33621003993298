import React from 'react'
import PropTypes from 'prop-types'

export default function Header(prop) {
    let navHeader = {
        position: 'fixed',
        top: '0',
        width: '100%'
    }
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary" style={navHeader}>
            <div className="container-sm">
                <a className="navbar-brand" href="/TodoList/"><strong>{prop.title}</strong></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                    </ul>
                    {prop.searchBar ? <form className="d-flex " role="search">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form> : "No Search Bar"}
                </div>
            </div>
        </nav>
    )
}

Header.defaultProps = {
    title: "Your Title Here",
    searchBar: true
}

Header.propTypes = {
    title: PropTypes.string,
    searchBar: PropTypes.bool.isRequired
}