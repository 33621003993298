import React from 'react'
import TodoItem from './TodoItem'

const Todos = (props) => {
    let myStyle = {
        // minHeight: "80vh",
        margin: "50px auto"
    }
    return (
        <div className='container' style={myStyle}>
            <h4 className='my-3'>Todos List</h4>
            {
                props.todos.length === 0 ? "No Todos to Display" :

                    props.todos.map((todo) => {
                        return (
                            <TodoItem todo={todo} key={todo.sno} onDelete={props.onDelete} />)
                    })
            }
            {/* for showing todo list items */}
            {/* <TodoItem todo={props.todos[0]} /> */}
        </div>
    )
}

export default Todos
