import React, { useState } from 'react';
import { RxCross2 } from "react-icons/rx";

const TodoItem = ({ todo, onDelete }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckbox = () => {
        setIsChecked(prevState => !prevState);
    };

    return (
        <>
            <div className="form-check clearfix">
                <div className="float-start">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckbox}
                    />
                    <h5 className="form-check-label ms-2">
                        {isChecked ? <del>{todo.title}</del> : todo.title}
                    </h5>
                </div>

                <RxCross2 onClick={() => onDelete(todo)} className='float-end' />

            </div>
            <hr />

            {/* <div class="form-check clearfix">
                <div className='float-start'>
                    <input class="form-check-input " type="checkbox" value="" id="flexCheckDefault" />
                    <label class="form-check-label " for="flexCheckDefault">
                        Default checkbox
                    </label>

                </div>

                <RxCross2 onClick={() => onDelete(todo)} className='float-end' />

            </div>

            <div class="bg-info clearfix">
                <button type="button" class="btn btn-secondary float-start">Example Button floated left</button>
                <button type="button" class="btn btn-secondary float-end">Example Button floated right</button>
            </div> */}

            {/* </div > */}
        </>
    );
}

export default TodoItem;
