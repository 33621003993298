import React from 'react';

const Footer = () => {

    return (
        <footer className='text-dark' style={{ position: 'fixed', bottom: '-10px', width: '100%' }}>
            <p className='text-center text-lg py-3 mb-0 bg-body-tertiary' id='footer'>
                <strong>
                    This TodoList Created by Harshit <span role='img' aria-label="Developer">👩‍💻</span>.
                </strong>
            </p>
        </footer>
    );
}

export default Footer;
